import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useSiteMetadata } from "../../hooks/use-site-metadata"

function SEO({ description, lang, title, socialImg, robots, canonical }) {
  const { siteTitle, siteUrl, author } = useSiteMetadata()
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${siteTitle}`}
    >
      <meta name="description" content={description} />
      <link rel="canonical" href={canonical} />
      {robots && <meta name="robots" content={robots} />}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title + ` | ${siteTitle}`} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonical} />
      {socialImg && <meta property="og:image" content={siteUrl + socialImg} />}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:title" content={title + ` | ${siteTitle}`} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: "es",
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SEO
