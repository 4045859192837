import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import IconClose from "../icons/icon-close"
import IconHamburguer from "../icons/icon-hamburguer"

const MobileNavigation = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 50px;
  background: #333;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
  padding: 10px 1rem;
  & > .position {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    width: 100%;
    height: 100%;
    & > a {
      text-decoration: none;
    }
  }
`

const Hamburguer = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  margin-left: auto;
  & > span {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
`

const MenuWrapper = styled.div`
  width: 0;
  height: auto;
  position: absolute;
  right: 0;
  bottom: 120px;
  &.is-open {
    transition: all 0.3s;
    transform: translateX(16px);
    width: 250px;
    z-index: 999;
  }
`

const MenuLinks = styled.ul`
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  background: #333;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
  padding: 0;
  margin: 0;
  & > li {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
    & > a {
      display: block;
      height: auto;
      text-align: center;
      padding: 0.5rem 0;
      border-bottom: 1px solid #999;
      text-decoration: none;
      transition: all 0.8s;
      &.active {
        font-weight: 700;
        color: red;
      }
    }
    &:last-child {
      & > a {
        border-bottom: none;
      }
    }
  }
`

const SiteName = styled.p`
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  & > span {
    font-size: 1rem;
    font-weight: 200;
  }
`

const NavMobile = () => {
  const [open, setOpen] = React.useState(false)
  return (
    <MobileNavigation>
      <div className="position">
        <Link to="/" title="Inicio">
          <SiteName>
            VipClass<span>.taxi</span>
          </SiteName>
        </Link>
        <Hamburguer
          onClick={() => setOpen(!open)}
          aria-expanded={open}
          aria-controls="menu"
        >
          {open ? <IconClose /> : <IconHamburguer />}
          <span>Menú</span>
        </Hamburguer>
        <MenuWrapper className={open ? "is-open" : null}>
          {open && (
            <MenuLinks>
              <li>
                <Link to="/" activeClassName="active">
                  Inicio
                </Link>
              </li>
              <li>
                <a
                  href="mailto:reservas@vipclass.taxi"
                  activeClassName="active"
                >
                  reservas@vipclass.taxi
                </a>
              </li>
            </MenuLinks>
          )}
        </MenuWrapper>
      </div>
    </MobileNavigation>
  )
}

export default NavMobile
