import React from "react"
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import NavMobile from "./navigation/NavMobile"
import CookieConsent from "react-cookie-consent"
import { useLocation } from "@reach/router"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

const Document = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1920px;
  min-height: 100vh;
  padding: 0;
  margin: 0 auto;
  div.CookieConsent {
    position: fixed;
    width: 100%;
    z-index: 9999;
    background: #222222;
    color: #ffffff;
    font-size: 1rem;
    padding: 1rem;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    > div:last-child {
      padding: 1rem 0;
      width: 100%;
      display: flex;
      justify-content: center;
      > * {
        flex: 0 1 auto;
      }
    }
    @media (min-width: 768px) {
      > div:first-of-type {
        flex: 0 1 60%;
      }
      > div:last-child {
        flex: 0 1 40%;
      }
    }
    @media (min-width: 1024px) {
      justify-content: center;
      > div:first-of-type {
        flex: 0 1 50%;
      }
      > div:last-child {
        flex: 0 1 40%;
      }
    }
    @media (min-width: 1200px) {
      justify-content: flex-end;
      > div:first-of-type {
        flex: 0 1 60%;
      }
      > div:last-child {
        flex: 0 1 30%;
      }
    }
    @media (min-width: 1440px) {
      justify-content: flex-end;
      > div:first-of-type {
        flex: 0 1 50%;
      }
      > div:last-child {
        flex: 0 1 30%;
      }
    }
  }
`

const Footer = styled.footer`
  margin-top: auto;
  background: #000;
  color: #fff;
  width: 100%;
  padding: 16px;
  text-align: center;
  margin-bottom: 50px;

  @media (min-width: 1024px) {
    margin-bottom: 0px;
  }
`

const Layout = ({ children }) => {
  let location = useLocation()
  const breakpoints = useBreakpoint()
  return (
    <Document role="document">
      <Global
        styles={css`
          *,
          ::before,
          ::after {
            box-sizing: border-box;
          }
          html,
          body,
          div,
          span,
          object,
          iframe,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p,
          blockquote,
          pre,
          abbr,
          address,
          cite,
          code,
          del,
          dfn,
          em,
          img,
          ins,
          kbd,
          q,
          samp,
          small,
          strong,
          sub,
          sup,
          var,
          b,
          i,
          dl,
          dt,
          dd,
          ol,
          ul,
          li,
          fieldset,
          form,
          label,
          legend,
          table,
          caption,
          tbody,
          tfoot,
          thead,
          tr,
          th,
          td,
          article,
          aside,
          canvas,
          details,
          figcaption,
          figure,
          footer,
          header,
          hgroup,
          menu,
          nav,
          section,
          summary,
          time,
          mark,
          audio,
          video {
            margin: 0;
            padding: 0;
            border: 0;
            outline: 0;
            font-size: 100%;
            vertical-align: baseline;
            background: transparent;
          }
          article,
          aside,
          details,
          figcaption,
          figure,
          footer,
          header,
          hgroup,
          menu,
          nav,
          section {
            display: block;
          }
          body {
            margin: 0;
            font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
              Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
              sans-serif;
            font-size: 14px;
            line-height: 1.5;
            color: #333;
            overflow-x: hidden;
          }
          main {
            width: 100%;
          }
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            margin: 0 0 0.5em 0;
            font-weight: 400;
            line-height: 1.2;
          }

          h1 {
            font-size: 2em;
          }

          a {
            color: inherit;
          }
        `}
      />
      {children}
      {(breakpoints.mobile || breakpoints.tablet) && <NavMobile />}
      <Footer>© 2021, VipClass.taxi</Footer>
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText="Entendido"
        declineButtonText="No acepto"
        cookieName="gatsby-gdpr-google-tagmanager"
        extraCookieOptions={{ domain: ".vipclass.taxi" }}
        disableStyles={true}
        contentClasses="cookie-text"
        declineButtonStyle={{
          color: "#777777",
          fontSize: "1rem",
          border: "none",
          background: "#eeeeee",
          borderRadius: 50,
          padding: "10px",
          marginRight: "1rem",
        }}
        buttonStyle={{
          color: "#ffffff",
          fontSize: "1rem",
          background: "#21a03d",
          borderRadius: 50,
          padding: "10px 40px",
          border: "none",
        }}
        expires={365}
        onAccept={() => {
          initializeAndTrack(location)
        }}
      >
        Utilizamos cookies propias y de terceros para mejorar la experiencia del
        usuario a través de su navegación. Ver{" "}
        <a href="/politica-cookies/">política de cookies</a>
      </CookieConsent>
    </Document>
  )
}

export default Layout
