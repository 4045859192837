/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Link } from "gatsby"
import PageHeading from "../../elements/PageHeading"
import HeaderText from "../../elements/HeaderText"
import Cta from "../../elements/Cta"

const HeaderSimple = ({ headerData }) => {
  const isSSR = typeof window === "undefined"
  const breakpoints = useBreakpoint()
  const { h1, mainHeading, subHeading, ctaLink } = headerData

  if (breakpoints.portrait || breakpoints.desktop) {
    var NavDesktop = React.lazy(() => import("../navigation/NavDesktop"))
  }

  return (
    <header
      css={css`
        position: relative;
        width: 100%;
        min-height: 40vh;
        z-index: 10;
        background: rgba(0, 0, 0, 0.8);
        color: white;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        @media (min-width: 768px) {
          height: 25vh;
        }
        @media (min-width: 1024px) {
          height: 30vh;
          padding-top: 50px;
        }
        @media (min-width: 1200px) {
          padding-top: 50px;
        }
      `}
    >
      {!isSSR && (breakpoints.portrait || breakpoints.desktop) && (
        <React.Suspense fallback={<div />}>
          <NavDesktop simple={true} />
        </React.Suspense>
      )}
      {(h1 && <PageHeading>{h1}</PageHeading>) || "Esta página necesita un H1"}
      {mainHeading && <HeaderText>{mainHeading}</HeaderText>}
      {subHeading && <HeaderText>{subHeading}</HeaderText>}
      {ctaLink && (
        <Cta
          title={ctaLink.title}
          as={Link}
          to={ctaLink.to}
          background="transparent"
          backgroundover="white"
          border="white"
          color="white"
          colorover="black"
          margin="1rem 0"
          padding="5px 1rem"
        >
          {ctaLink.text}
        </Cta>
      )}
    </header>
  )
}

export default HeaderSimple
