import styled from "@emotion/styled"
import { css } from "@emotion/core"

const dinamicStyle = props =>
  css`
    margin-bottom: ${props.margindesktop};
  `

const PageHeading = styled.h1`
  font-weight: 700;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 2rem;
  }
  @media (min-width: 1024px) {
    font-size: 2.1rem;
    ${dinamicStyle};
  }
  @media (min-width: 1200px) {
    font-size: 2.5rem;
  }
`

export default PageHeading
