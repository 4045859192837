import React from "react"

const IconClose = () => (
  <svg
    aria-labelledby="closeTitle closeDescription"
    role="image"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="white"
    style={{ width: "2rem" }}
  >
    <title id="closeTitle">Botón cierre menú móvil</title>
    <desc id="closeDescription">Cierra el menú móvil de la web</desc>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
)

export default IconClose
