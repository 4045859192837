import styled from "@emotion/styled"
import { css } from "@emotion/core"

const dinamicStyle = props =>
  css`
    background: ${props.background};
    color: ${props.color};
    border-color: ${props.border};
    margin: ${props.margin};
    padding: ${props.padding};
    &:hover {
      background: ${props.backgroundover};
      color: ${props.colorover};
      border-color: ${props.borderover};
    }
  `

const Cta = styled.a`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  border-style: solid;
  border-width: 3px;
  text-decoration: none;
  font-weight: 700;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  max-width: 200px;
  min-width: 200px;
  transition: all 0.8s;
  ${dinamicStyle};
`

export default Cta
