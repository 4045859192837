import styled from "@emotion/styled"

const HeaderText = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
  max-width: 90vw;
  &.subheading {
    font-size: 0.9rem;
  }
  @media (min-width: 768px) {
    max-width: 80vw;
    font-size: 1.1rem;
    &.subheading {
      font-size: 0.95rem;
    }
  }
  @media (min-width: 1024px) {
    font-size: 1.2rem;
    &.subheading {
      font-size: 1rem;
    }
  }
  @media (min-width: 1200px) {
    font-size: 1.5rem;
  }
`

export default HeaderText
